import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, FlexBox } from '@quikr/ui';
import config from '../../../config';
const { IMAGE_CDN_DOMAIN } = config;

const BackGround = styled.div`
  background-color: ${({ theme }) => theme.colors.Neutral[400]};
  margin-bottom: -32px;
`;

const ErrorWrapper = styled(FlexBox.Container)`
  padding: 20px 12px 50px;
  @media screen and (min-width: 768px) {
    margin-top: 91px;
    max-width: 850px;
    padding: 20px 0 50px;
  }
`;

const Image = styled.img`
  max-width: 418px;
`;

const TextMessage = styled.div`
  margin-top: 25px;
  line-height: 30px;
`;

const LinkTypo = styled(Typography)`
  color: ${({ theme }) => theme.colors.Primary};
`;

const Error = ({ errorCode }) => {

  useEffect(() => {
    gtag('event', 'page_view', {
      page_title: errorCode === '404' ? '404 Error': 'Server Error'
    });
  }, [errorCode]);

  const errorText =
    errorCode === '500'
      ? 'Due to an error, your request could not be processed. Please try after some time.'
      : 'We couldn’t find that page. It either moved or doesn’t exist anymore.';
  return (
    <BackGround>
      <ErrorWrapper className="taC">
        <Image
          className="w100"
          src={`${IMAGE_CDN_DOMAIN}/core/clsfd/assets/${errorCode || '404'}Error.png`}
        />
        <TextMessage>
          <Typography tagName="p" modifiers={['h2']}>
            {errorText}
          </Typography>
          <Typography className="dIB" tagName="p" modifiers={['h2']}>
            head back&nbsp;
          </Typography>
          <LinkTypo className="dIB" tagName="a" modifiers={['h2']} href="/">
            home.
          </LinkTypo>
        </TextMessage>
      </ErrorWrapper>
    </BackGround>
  );
};

Error.propTypes = {
  errorCode: PropTypes.string
};

Error.defaultProps = {
  errorCode: '404'
};

export default Error;
